import { render, staticRenderFns } from "./ticket1.vue?vue&type=template&id=00f3236b&scoped=true&"
import script from "./ticket1.vue?vue&type=script&lang=ts&"
export * from "./ticket1.vue?vue&type=script&lang=ts&"
import style0 from "./ticket1.vue?vue&type=style&index=0&id=00f3236b&lang=less&scoped=true&"
import style1 from "./ticket1.vue?vue&type=style&index=1&id=00f3236b&lang=less&scoped=true&"
import style2 from "./ticket1.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f3236b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_Z6yh/components/SectionTitle/index.vue').default,SingleUpload: require('/root/workspace/com.jufair.vue_Z6yh/components/SingleUpload/index.vue').default})
